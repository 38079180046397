<template>
  <div class="order-review_wrapper">
    <div class="order-review_header">Order Review</div>
    <div class="order-review_body">
      <div class="order-review_title">
        <div class="logo">
          <image-component
            :path="channelSettings.content['channel-logo']"
            alt="Main logo"
          />
        </div>
        <div class="name">
          <span>{{orderData.name}}</span>
          <span v-if="summary" class="small">{{summary}}</span>
        </div>
        <div class="price">{{price}} {{channelSettings.currency}}</div>
      </div>
      <div class="order_discount" v-if="needInput">
        <div class="loading-popup" v-show="promoProcessing">
          <loader/>
        </div>
        <input
            class="discount_input"
            placeholder="Gift card or discount code"
            v-model="code"
            @focus="onPromoFocus"
        />
        <button-component
            class="discount_button"
            @btn-click="onCouponApply"
            :disabled="promoProcessing"
        >{{applied? "Clear": "Apply"}}</button-component>
      </div>
      <div class="promo-error">
        {{error}}
      </div>
      <div class="price_grid">
        <span>Subtotal</span><span>{{orderData.basePrice}} {{channelSettings.currency}}</span>
        <template v-if="discount">
          <span>Discount</span><span>{{discount}} {{channelSettings.currency}}</span>
        </template>
        <span class="slim">Estimated taxes</span><span>{{orderData.fee}} {{channelSettings.currency}}</span>
        <span class="total">Total</span><span>{{total}} {{channelSettings.currency}}</span>
      </div>
    </div>
  </div>
</template>

<script>
  import ButtonComponent from "../base-components/buttons/ButtonComponent";
  import Ush_second_logo from "../../assets/img/ush_second_logo";
  import {ERROR} from "../../const";
  import {mapActions, mapState} from "vuex";
  import ImageComponent from "../base-components/ImageComponent";
  import Loader from "../Loader";
  export default {
    name: "OrderReview",
    props: {
      summary: {
        type: String,
      },
      orderData: {
        type: Object
      },
      type: {
        type: String
      },
      id: {
        type: [String, Number]
      },
      needInput:{
        type: Boolean,
      },
    },
    data(){
      return{
        discount: null,
        promoPrice: null,
        promoTotal: null,
        applied: false,
        code: null,
        promoProcessing: false,
        freeAccess: false,
        error: ''
      }
    },
    components: {Loader, ImageComponent, Ush_second_logo, ButtonComponent},
    watch: {
      applied(){
        this.$emit('promoChange', this.applied && {
          couponName: this.code,
          discount: Number(this.discount),
          freeAccess: this.freeAccess,
        })
      },
    },
    computed: {
      ...mapState({
        channelSettings: state => state.channel.settings,
      }),
      total(){
        return this.applied? parseFloat(String(this.promoTotal)).toFixed(2) :
            (parseFloat(this.orderData.basePrice) + parseFloat(this.orderData.fee)).toFixed(2);
      },
      price(){
        return this.applied?  this.promoPrice : this.orderData.price
      }
    },
    methods: {
      ...mapActions({
        applyCoupon: 'APPLY_COUPON'
      }),
      onCouponApply(){
        this.applied? this.clearPromo() : this.checkPromo()
      },
      checkPromo() {
        if (!this.code) return false;
        this.promoProcessing = true;
        this.error = '';
        const params = Object.fromEntries(Object.entries(        {
          code: this.code.toUpperCase(),
          id: this.id,
          type: this.type,
          single_event_package_id: this.orderData.single_event_package_id
        }).filter(([_, val]) => val));

        this.applyCoupon(params).then(({data}) => {
          this.applied = true;
          const {
            price,
            discount,
            payAmount,
          } = data;

          this.error = '';
          this.freeAccess = false;
          this.discount = discount;
          this.promoPrice = price;
          this.promoTotal = payAmount;
          this.freeAccess = !Boolean(this.promoData.total)
        }).catch((data) => {
          if (Number(data.status) === 400) {
            this.error = this.$t(ERROR.WRONG_PROMOCODE)
            this.applied = false;
          }
        }).finally(_ => {
          this.promoProcessing = false;
        })
      },
      clearPromo() {
        this.error = '';
        this.applied = false;
        this.discount = null;
        this.code = '';
        this.freeAccess = false;
      },
      onPromoFocus() {
        if (this.error) {
          this.error = '';
        }
      },
    }
  }
</script>

<style scoped lang="scss">
  .order-review_wrapper{
    --background-review-color: #FFFFFF;
    --placeholder-color: #A3ACBA;
    --border-color: #D5DBE1;
    --secondary-background: #003876;
    border-radius: 10px;
    background-color: var(--background-review-color);
    overflow: hidden;
    color: var(--font-color);
    text-align: left;
  }

  .order-review_header{
    background-color: var(--secondary-background);
    color: var(--background-review-color);
    font-size: 1.3rem;
    line-height: 1.45rem;
    padding: 1rem;
    text-align: center;
  }

  .order-review_body{
    padding: 2.5rem;
  }

  .order-review_title{
    display: flex;
    width: 100%;
    align-items: center;
    gap: 1.3rem;
    margin-bottom: 2.5rem;
    .logo{
      border: 1px solid var(--secondary-background);
      border-radius: 5px;
      padding: .3rem .2rem .2rem;
      line-height: 1;
      img{
        height: 2rem;
        object-fit: contain;
      }
    }
    .name{
      font-weight: 500;
      line-height: 1.1;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
    }
    .price{
      justify-self: flex-end;
    }
  }
  .order_discount{
    display: flex;
    gap: 1.3rem;
    position: relative;
    .loading-popup{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      backdrop-filter: blur(1px);
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .discount_input{
      line-height: 1.3rem;
      padding: 1.1rem .5rem;
      border: 1px solid var(--border-color);
      border-radius: 4px;
      width: 100%;
    }
    .discount_button{
      background-color: var(--secondary);
      border-radius: 5px;
      font-style: normal;
      font-weight: 500;
      font-size: 1rem;
      line-height: 1;
      box-shadow: none;
      text-transform: none;
      &:hover{
        box-shadow: none;
      }
      &:disabled{
        filter: opacity(0.6) grayscale(.5) brightness(1.7) contrast(1.9);
      }
    }
  }
  .price_grid{
    display: grid;
    grid-template-columns: 1fr max-content;
    row-gap: 1.3rem;
    line-height: 1.2rem;
    margin-bottom: 2.9rem;
    margin-top: 3.1rem;
    span:nth-child(2n){
      text-align: right;
    }
    .slim{
      font-weight: 400;
    }
    .total{
      font-size: 1.1rem;
      line-height: 1.4rem;
    }
  }

  .promo-error{
    color: rgb(209, 29, 29);
    margin-top: 1rem;
  }
</style>